export const notesToMidiNumbers = {
    "Cb-2": -1,
    "C-2": 0,
    "C#-2": 1,
    "Db-2": 1,
    "D-2": 2,
    "D#-2": 3,
    "Eb-2": 3,
    "E-2": 4,
    "E#-2": 5,
    "Fb-2": 4,
    "F-2": 5,
    "F#-2": 6,
    "Gb-2": 6,
    "G-2": 7,
    "G#-2": 8,
    "Ab-2": 8,
    "A-2": 9,
    "A#-2": 10,
    "Bb-2": 10,
    "B-2": 11,
    "B#-2": 12,
    "Cb-1": 11,
    "C-1": 12,
    "C#-1": 13,
    "Db-1": 13,
    "D-1": 14,
    "D#-1": 15,
    "Eb-1": 15,
    "E-1": 16,
    "E#-1": 17,
    "Fb-1": 16,
    "F-1": 17,
    "F#-1": 18,
    "Gb-1": 18,
    "G-1": 19,
    "G#-1": 20,
    "Ab-1": 20,
    "A-1": 21,
    "A#-1": 22,
    "Bb-1": 22,
    "B-1": 23,
    "B#-1": 24,
    Cb0: 23,
    C0: 24,
    "C#0": 25,
    Db0: 25,
    D0: 26,
    "D#0": 27,
    Eb0: 27,
    E0: 28,
    "E#0": 29,
    Fb0: 28,
    F0: 29,
    "F#0": 30,
    Gb0: 30,
    G0: 31,
    "G#0": 32,
    Ab0: 32,
    A0: 33,
    "A#0": 34,
    Bb0: 34,
    B0: 35,
    "B#0": 36,
    Cb1: 35,
    C1: 36,
    "C#1": 37,
    Db1: 37,
    D1: 38,
    "D#1": 39,
    Eb1: 39,
    E1: 40,
    "E#1": 41,
    Fb1: 40,
    F1: 41,
    "F#1": 42,
    Gb1: 42,
    G1: 43,
    "G#1": 44,
    Ab1: 44,
    A1: 45,
    "A#1": 46,
    Bb1: 46,
    B1: 47,
    "B#1": 48,
    Cb2: 47,
    C2: 48,
    "C#2": 49,
    Db2: 49,
    D2: 50,
    "D#2": 51,
    Eb2: 51,
    E2: 52,
    "E#2": 53,
    Fb2: 52,
    F2: 53,
    "F#2": 54,
    Gb2: 54,
    G2: 55,
    "G#2": 56,
    Ab2: 56,
    A2: 57,
    "A#2": 58,
    Bb2: 58,
    B2: 59,
    "B#2": 60,
    Cb3: 59,
    C3: 60,
    "C#3": 61,
    Db3: 61,
    D3: 62,
    "D#3": 63,
    Eb3: 63,
    E3: 64,
    "E#3": 65,
    Fb3: 64,
    F3: 65,
    "F#3": 66,
    Gb3: 66,
    G3: 67,
    "G#3": 68,
    Ab3: 68,
    A3: 69,
    "A#3": 70,
    Bb3: 70,
    B3: 71,
    "B#3": 72,
    Cb4: 71,
    C4: 72,
    "C#4": 73,
    Db4: 73,
    D4: 74,
    "D#4": 75,
    Eb4: 75,
    E4: 76,
    "E#4": 77,
    Fb4: 76,
    F4: 77,
    "F#4": 78,
    Gb4: 78,
    G4: 79,
    "G#4": 80,
    Ab4: 80,
    A4: 81,
    "A#4": 82,
    Bb4: 82,
    B4: 83,
    "B#4": 84,
    Cb5: 83,
    C5: 84,
    "C#5": 85,
    Db5: 85,
    D5: 86,
    "D#5": 87,
    Eb5: 87,
    E5: 88,
    "E#5": 89,
    Fb5: 88,
    F5: 89,
    "F#5": 90,
    Gb5: 90,
    G5: 91,
    "G#5": 92,
    Ab5: 92,
    A5: 93,
    "A#5": 94,
    Bb5: 94,
    B5: 95,
    "B#5": 96,
    Cb6: 95,
    C6: 96,
    "C#6": 97,
    Db6: 97,
    D6: 98,
    "D#6": 99,
    Eb6: 99,
    E6: 100,
    "E#6": 101,
    Fb6: 100,
    F6: 101,
    "F#6": 102,
    Gb6: 102,
    G6: 103,
    "G#6": 104,
    Ab6: 104,
    A6: 105,
    "A#6": 106,
    Bb6: 106,
    B6: 107,
    "B#6": 108,
    Cb7: 107,
    C7: 108,
    "C#7": 109,
    Db7: 109,
    D7: 110,
    "D#7": 111,
    Eb7: 111,
    E7: 112,
    "E#7": 113,
    Fb7: 112,
    F7: 113,
    "F#7": 114,
    Gb7: 114,
    G7: 115,
    "G#7": 116,
    Ab7: 116,
    A7: 117,
    "A#7": 118,
    Bb7: 118,
    B7: 119,
    "B#7": 120,
    Cb8: 119,
    C8: 120,
    "C#8": 121,
    Db8: 121,
    D8: 122,
    "D#8": 123,
    Eb8: 123,
    E8: 124,
    "E#8": 125,
    Fb8: 124,
    F8: 125,
    "F#8": 126,
    Gb8: 126,
    G8: 127,
    "G#8": 128,
    Ab8: 128,
    A8: 129,
    "A#8": 130,
    Bb8: 130,
    B8: 131,
    "B#8": 132,
  };

export const midiNumbersToNotes = {
  0: "C-2",
  1: "Db-2",
  2: "D-2",
  3: "Eb-2",
  4: "Fb-2",
  5: "F-2",
  6: "Gb-2",
  7: "G-2",
  8: "Ab-2",
  9: "A-2",
  10: "Bb-2",
  11: "Cb-1",
  12: "C-1",
  13: "Db-1",
  14: "D-1",
  15: "Eb-1",
  16: "Fb-1",
  17: "F-1",
  18: "Gb-1",
  19: "G-1",
  20: "Ab-1",
  21: "A-1",
  22: "Bb-1",
  23: "Cb0",
  24: "C0",
  25: "Db0",
  26: "D0",
  27: "Eb0",
  28: "Fb0",
  29: "F0",
  30: "Gb0",
  31: "G0",
  32: "Ab0",
  33: "A0",
  34: "Bb0",
  35: "Cb1",
  36: "C1",
  37: "Db1",
  38: "D1",
  39: "Eb1",
  40: "Fb1",
  41: "F1",
  42: "Gb1",
  43: "G1",
  44: "Ab1",
  45: "A1",
  46: "Bb1",
  47: "Cb2",
  48: "C2",
  49: "Db2",
  50: "D2",
  51: "Eb2",
  52: "Fb2",
  53: "F2",
  54: "Gb2",
  55: "G2",
  56: "Ab2",
  57: "A2",
  58: "Bb2",
  59: "Cb3",
  60: "C3",
  61: "Db3",
  62: "D3",
  63: "Eb3",
  64: "Fb3",
  65: "F3",
  66: "Gb3",
  67: "G3",
  68: "Ab3",
  69: "A3",
  70: "Bb3",
  71: "Cb4",
  72: "C4",
  73: "Db4",
  74: "D4",
  75: "Eb4",
  76: "Fb4",
  77: "F4",
  78: "Gb4",
  79: "G4",
  80: "Ab4",
  81: "A4",
  82: "Bb4",
  83: "Cb5",
  84: "C5",
  85: "Db5",
  86: "D5",
  87: "Eb5",
  88: "Fb5",
  89: "F5",
  90: "Gb5",
  91: "G5",
  92: "Ab5",
  93: "A5",
  94: "Bb5",
  95: "Cb6",
  96: "C6",
  97: "Db6",
  98: "D6",
  99: "Eb6",
  100: "Fb6",
  101: "F6",
  102: "Gb6",
  103: "G6",
  104: "Ab6",
  105: "A6",
  106: "Bb6",
  107: "Cb7",
  108: "C7",
  109: "Db7",
  110: "D7",
  111: "Eb7",
  112: "Fb7",
  113: "F7",
  114: "Gb7",
  115: "G7",
  116: "Ab7",
  117: "A7",
  118: "Bb7",
  119: "Cb8",
  120: "C8",
  121: "Db8",
  122: "D8",
  123: "Eb8",
  124: "Fb8",
  125: "F8",
  126: "Gb8",
  127: "G8",
  128: "Ab8",
  129: "A8",
  130: "Bb8",
  131: "B8",
  132: "B#8",
};
